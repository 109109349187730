<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <UserForm :profile="true" :item="item" @save="save" />
    </v-card>
  </v-container>
</template>

<script>
import UserForm from "./UserForm";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserProfile",
  components: { UserForm },
  data() {
    return {
      item: {},
    };
  },
  computed: {
    ...mapGetters("users", ["getUserProfile"]),
  },
  mounted() {
    this.loadOrganizations();

    const item = this.getUserProfile();
    if (item) {
      this.item = item;
      this.refreshBreadcrumb();
    } else {
      this.loadUserProfile().then(() => {
        this.item = this.getUserProfile();
        this.refreshBreadcrumb();
      });
    }
  },
  methods: {
    ...mapActions("organizations", ["loadOrganizations"]),
    ...mapActions("users", ["loadUserProfile", "saveUserProfile"]),
    ...mapActions("ui", ["setBreadcrumbs"]),

    refreshBreadcrumb() {
      const updatedBreadcrumbs = this.$route.meta.breadcrumb.map((item) => {
        if (item.dynamic && this.item) {
          return { ...item, name: this.item.username };
        }
        return item;
      });
      this.setBreadcrumbs(updatedBreadcrumbs);
    },
    save(params) {
      const { item } = params;
      this.saveUserProfile({ item });
      this.$router.go(-1);
    },
  },
};
</script>
