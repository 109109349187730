<template>
  <v-card flat>
    <v-toolbar flat dense class="grey lighten-5">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-toolbar-title class="py-3 px-0">
        <span v-if="editMode && !profile">
          {{ $t("user.edit") }}
        </span>
        <span v-if="profile">
          {{ $t("route.profile") }}
        </span>
        <span v-if="!editMode">
          {{ $t("user.new") }}
        </span>
        <v-icon v-show="editMode && changed" color="primary"
          >mdi-textbox-password</v-icon
        >
      </v-toolbar-title>
      <v-spacer />
      <v-chip class="px-2" small label v-if="isReadOnly" color="yellow">
        <v-avatar left>
          <v-icon small>mdi-alert</v-icon>
        </v-avatar>
        {{ $t("general.readonly") }}
      </v-chip>
      <v-divider v-if="!editMode" vertical inset class="mx-2" />
      <v-btn v-if="!editMode" small text @click="keepOpen = !keepOpen">
        <v-icon v-show="keepOpen" color="primary">mdi-pin</v-icon>
        <v-icon v-show="!keepOpen" small>mdi-pin-outline</v-icon>
        <span class="text--secondary">{{ $t("general.keepopen") }}</span>
      </v-btn>
      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-btn
        :disabled="isReadOnly || !valid"
        small
        text
        color="success"
        class="mx-2"
        @click="save"
      >
        <v-icon>mdi-content-save</v-icon>
        <span class="text--secondary">{{ $t("general.save") }}</span>
      </v-btn>
    </v-toolbar>

    <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight()">
      <v-form ref="form" v-model="valid" class="py-10 px-5">
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.firstName"
                  :counter="64"
                  :rules="rules.firstName"
                  :label="$t('user.firstName')"
                  required
                  dense
                  :readonly="isReadOnly"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="item.lastName"
                  :counter="64"
                  :rules="rules.lastName"
                  :label="$t('user.lastName')"
                  required
                  dense
                  :readonly="isReadOnly"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.username"
                  :counter="50"
                  :rules="rules.username"
                  :label="$t('user.username')"
                  required
                  :disabled="editMode && !$hasRole('Administrator')"
                  dense
                  :readonly="isReadOnly"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="item.email"
                  :rules="rules.email"
                  :label="$t('user.email')"
                  append-icon="mdi-at"
                  required
                  :disabled="!($hasRole('Administrator') || $hasRole('Resource Manager'))"
                  dense
                  :readonly="isReadOnly"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.position"
                  counter="50"
                  :label="$t('user.position')"
                  dense
                  :readonly="isReadOnly"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="item.phone"
                  :rules="rules.phone"
                  :label="$t('user.phone')"
                  dense
                  :readonly="isReadOnly"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  v-if="!profile"
                  v-model="item.organizationId"
                  :items="getOrganizations"
                  item-text="name"
                  item-value="id"
                  :rules="rules.org"
                  :label="$t('user.organization')"
                  required
                  :disabled="!editMode"
                  dense
                  :readonly="isReadOnly"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="editMode">
              <v-col>
                <v-checkbox
                  v-model="item.updatePassword"
                  :label="$t('user.updatePassword')"
                  dense
                  :readonly="isReadOnly"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-if="item.updatePassword || !editMode"
                  v-model="item.password"
                  :rules="rules.password"
                  :label="$t('user.password')"
                  append-icon="mdi-lock"
                  type="password"
                  required
                  dense
                  :readonly="isReadOnly"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-if="item.updatePassword || !editMode"
                  v-model="item.confirmPassword"
                  :rules="rules.confirm"
                  :label="$t('user.confirmPassword')"
                  append-icon="mdi-lock-check"
                  type="password"
                  required
                  dense
                  :readonly="isReadOnly"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-checkbox
                  v-if="!profile"
                  v-model="item.enabled"
                  :label="$t('user.active')"
                  dense
                  :readonly="isReadOnly"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-if="!editMode"
                  v-model="item.skipConfirmEmail"
                  :label="$t('user.skipConfirmEmail')"
                  dense
                  :readonly="isReadOnly"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5" v-if="editMode">
            <v-card tile>
              <v-sheet tile height="40" color="secondary" class="pa-3">
                <p class="v-label theme--light white--text">
                  {{ $t("user.avatar") }}
                </p>
              </v-sheet>
              <v-card-text>
                <div align="center">
                  <v-avatar size="126" color="primary">
                    <img
                      :src="!!tempAvatarImage ? tempAvatarImage : getAvatar"
                      :alt="item.username"
                    />
                  </v-avatar>
                </div>
                <v-file-input
                  v-model="avatarImage"
                  @change="avatarChanged"
                  @click:clear="clearAvatar"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pick an image"
                  prepend-icon="mdi-camera"
                  :label="$t('user.avatarFile')"
                  :disabled="isReadOnly || !editMode"
                  show-size
                  clearable
                  clear-icon="mdi-image-off"
                ></v-file-input>

                <v-alert
                  v-if="!editMode"
                  border="left"
                  colored-border
                  elevation="2"
                  type="info"
                  dense
                  class="body-2"
                >
                  You can update avatar after saving the user
                </v-alert>
                <v-divider class="my-3" />

                <v-btn
                  :disabled="isReadOnly || (!avatarImage && !editMode)"
                  tile
                  depressed
                  @click="uploadAvatar"
                >
                  <v-icon>mdi-progress-upload</v-icon>
                  Upload
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-divider class="mt-10" />
        <v-btn
          :disabled="isReadOnly || !valid"
          :dark="!isReadOnly && valid"
          color="success"
          class="mr-4"
          tile
          depressed
          @click="save"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $t("general.save") }}
        </v-btn>

        <v-btn
          :disabled="isReadOnly"
          color="primary"
          tile
          depressed
          @click="reset"
        >
          <v-icon>mdi-undo-variant</v-icon>
          {{ $t("general.undo") }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserForm",
  props: {
    profile: {
      type: Boolean,
    },
    item: {
      type: Object,
      required: true,
      default() {
        return {
          id: 0,
          username: "",
          firstName: "",
          lastName: "",
          email: "",
          position: "",
          phone: "",
          updatePassword: false,
          password: "",
          confirmPassword: "",
          avatar: null,
          enabled: false,
          organizationId: 0,
          skipConfirmEmail: false,
        };
      },
    },
  },
  data() {
    return {
      // item: {
      //   id: 0,
      //   username: "",
      //   firstName: "",
      //   lastName: "",
      //   email: "",
      //   organizationId: this.$route.params.org,
      //   updatePassword: false,
      //   password: "",
      //   confirmPassword: "",
      //   avatar: null,
      //   enabled: false,
      //   skipConfirmEmail: false,
      // },
      valid: false,
      keepOpen: false,
      originalState: { id: 0 },
      rules: {
        firstName: [
          (v) => !!v || "First name is required",
          (v) => (!!v && v.length >= 2) || "Name must be at least 2 characters",
          (v) =>
            (!!v && v.length <= 64) ||
            "Must be less than or eaqual to 64 characters",
        ],
        lastName: [
          (v) => !!v || "Last name is required",
          (v) => (!!v && v.length >= 2) || "Name must be at least 2 characters",
          (v) =>
            (!!v && v.length <= 64) ||
            "Must be less than or eaqual to 64 characters",
        ],
        username: [
          (v) => !!v || "Username is required",
          (v) =>
            (!!v && v.length >= 4) || "Username must be at least 4 characters",
          (v) =>
            (!!v && v.length <= 50) ||
            "Name must be less than or eaqual to 50 characters",
          (v) => {
            const re = /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Username must start with an alphanumeric character, " +
                "can have a single (- or _) sepcial charactor " +
                "and/or any number of alphanumeric characters within the limit"
            );
          },
        ],
        phone: [
          (v) => !v || v.length >= 8 || "Not less than 8 numbers",
          (v) => {
            const re = /^[+\-/0-9 ]*$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Only numbers, spaces and (+ - or /) sepcial charactors"
            );
          },
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            (!!v && v.length >= 8) || "Password must be at least 8 characters",
          (v) => {
            const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Minimum eight characters, at least one letter, one number and one special character"
            );
          },
        ],
        confirm: [
          (v) => !!v || "Password is required",
          (v) =>
            v === this.item.password || "Password and confirmation must match",
        ],
        email: [
          (v) => !!v || "Email is required",
          (v) => {
            const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
            return !!v && re.test(String(v).toLowerCase());
          },
        ],
      },
      avatarImage: null,
      tempAvatarImage: null,
    };
  },
  mounted() {
    // Object.assign(this.item, this.data);
    Object.assign(this.originalState, this.item);
  },
  computed: {
    ...mapGetters("organizations", ["getOrganizationNames"]),
    ...mapGetters("auth", ["getCurrentUser"]),

    isReadOnly() {
      return !this.$allowed("Editor") && !this.isCurrentUser;
    },
    isCurrentUser() {
      const { username } = this.$getUserInfo();
      return username === this.item.username;
    },
    getAvatar() {
      return `${process.env.ROOT_API}/files/avatar/${this.item.username}`;
    },
    getOrganizations() {
      return this.getOrganizationNames();
    },
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    idParam() {
      return parseInt(this.$route.params.id);
    },
    orgIdParam() {
      return parseInt(this.$route.params.org);
    },
    editMode() {
      return this.idParam > 0 || this.profile;
    },
  },
  methods: {
    ...mapActions("ui", ["addNotification"]),

    save() {
      if (!this.editMode) {
        this.item.updatePassword = true;
      }

      this.$emit("save", {
        item: this.item,
        // tempAvatarCode: this.tempAvatarCode,
        editMode: this.editMode,
        keepOpen: this.keepOpen,
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },

    avatarChanged(file) {
      console.log("New picture loaded");
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.tempAvatarImage = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        console.log("Old browser. No support for Filereader API");
      }
    },
    clearAvatar() {
      this.avatarImage = null;
      this.tempAvatarImage = null;
    },
    uploadAvatar() {
      if (this.avatarImage) {
        this.$upload(
          `/files/upload/avatar/${this.item.username}`,
          this.avatarImage
        )
          .then(() =>
            this.addNotification({
              text: "messages.updateCompleted",
              color: "success",
            })
          )
          .catch(() =>
            this.addNotification({
              text: "messages.updateNotSuccessful",
              color: "grey",
            })
          );
      }
    },
  },
};
</script>
